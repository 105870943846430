* {
  box-sizing: border-box;
}

html,
body {
  width: 100%;
  font-family: sans-serif;
  margin: 0;
}

html {
  min-height: 100%;
  display: grid;
  place-content: center;
  padding: 2vh 0em 5vh;
}

:root {
  --cell-scale: calc(min(min(12vw, 12vh), 80px));
}

#title {
  font-size: 2em;
  display: grid;
  place-content: center;
}

.subtitle {
  font-size: 1.4em;
  display: grid;
  place-content: center;
  margin-bottom: 0.5em;
}

#title {
  text-decoration: underline;
}

a.subtitle {
  color: blue;
  text-decoration: none;
}

table.game-of-life {
  background-color: black;
}

table.game-of-life td {
  border: 1px solid #888;
  border: 1px solid #888;
  width: var(--cell-scale);
  height: var(--cell-scale);

  transition: background-color 0.2s;
}

body.demo table.game-of-life .dot {
  padding-top: 0.1em;
  font-size: calc(var(--cell-scale) * 0.6);
}

table.game-of-life .dot {
  display: grid;
  place-content: center;
  font-family: "Gotham Black";
  font-weight: "bold";
  /* text-shadow: 0 0 0.2em white; */

  width: 90%;
  height: 90%;
  margin: 5%;
  border-radius: 100%;

  transition: background-color 0.2s;
}

table.game-of-life .alive-now .dot {
  -webkit-text-stroke: 1px rgba(255, 255, 255, 0.75);
}

table.game-of-life .alive-now.dead-next .dot {
  -webkit-text-stroke: 2px rgba(0, 0, 0, 0.75);
}

.number-1 {
  color: #0022e5;
}
.number-2 {
  color: #387f22;
}
.number-3 {
  color: #e83223;
}
.number-4 {
  color: #000c76;
}
.number-5 {
  color: #74150f;
}
.number-6 {
  color: #37807f;
}
.number-7 {
  color: #000000;
}
.number-8 {
  color: #808080;
}

table.game-of-life td {
  cursor: pointer;
  background: #fdb;
  border: none;
}

table.game-of-life td:hover {
  background: #fb7 !important;
  transition: background-color 0s;
}

table.game-of-life td.alive-now .dot {
  background: black;
  box-shadow: inset 0px 0px 0px calc(var(--cell-scale) / 8) black;
}

table.game-of-life td.alive-next:not(.alive-now) .dot {
  background: white;
  box-shadow: inset 0px 0px 0px 2px black;
}

table.game-of-life td.alive-now.dead-next .dot {
  --bg: rgb(255, 255, 63);
  --diag: rgb(191, 191, 127);
  background-color: rgb(255, 255, 63);
  background: repeating-linear-gradient(
    -45deg,
    var(--bg),
    var(--bg) calc(var(--cell-scale) / 12),
    var(--diag) calc(var(--cell-scale) / 12),
    var(--diag) calc(var(--cell-scale) / 6)
  );
  border: 1px solid;
}

table.game-of-life .correct {
  background: rgb(127, 255, 127) !important;
}

table.game-of-life .incorrect {
  --bg: rgb(255, 63, 63);
  --diag: rgba(255, 160, 160);
  background-color: red !important;
  background: linear-gradient(
      45deg,
      transparent 0%,
      transparent 45%,
      var(--diag) 45%,
      var(--diag) 55%,
      transparent 55%,
      transparent 100%
    ),
    linear-gradient(
      -45deg,
      var(--bg) 0%,
      var(--bg) 45%,
      var(--diag) 45%,
      var(--diag) 55%,
      var(--bg) 55%,
      var(--bg) 100%
    );
}

#board {
  width: 100%;
  display: grid;
  place-content: center;
}

#board .game-of-life,
body.timed #state-controls {
  user-select: none;
  -webkit-user-select: none;
  touch-action: none;
}

button {
  font-size: 1.5rem;
  padding: 1rem 0.25rem;
}

.column-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: calc(var(--cell-scale) / 8);
}

.auto-cols {
  grid-template-columns: auto auto;
}

.legend-heading {
  border-bottom: 1px solid black;
  padding: 0.25em 0.25em;
}

.legend {
  font-size: 1.5em;
}

.timer-display {
  display: none;
  font-size: 1.5em;
  padding: 0 5vw 0.5em;
  place-content: center;
}

.timer-display.visible {
  display: grid;
}

code {
  font-size: 1.2em;
}

#time-wrapper {
  display: grid;
  place-content: center;
  font-size: 5em;
  padding-bottom: 0.2em;
}

.explanation {
  font-size: 1.5em;
}

body.timed .for-untimed {
  display: none !important;
}

body:not(.timed) .for-timed {
  display: none !important;
}

#stats {
  font-size: 1.5em;
  border: 1px solid black;
  padding: 1em 0.5em;
}

#stats .label {
  text-align: right;
}

button {
  background-color: rgba(0, 0, 0, 0.1);
  border: 2px solid rgba(0, 0, 0, 0.5);
  border-radius: 0.25em;
}

button:not(:active) {
  transition: background-color 0.5s;
}

button:active {
  background-color: #4285f4;
  color: white;
}

#state-controls button {
  -webkit-appearance: none;
  min-height: 4em;
}

.generation-wrapper {
  font-size: 1.5em;
  display: grid;
  place-content: center;
  margin-bottom: 1em;
}

.neighbor-mark {
  background: rgba(255, 255, 255, 0.75) !important;
  box-shadow: inset 0 0 8px rgba(0, 0, 0, 1);
}

table.game-of-life td.neighbor-mark:hover {
  background: rgba(255, 230, 191, 0.85) !important;
}

body.demo {
  display: grid;
  grid-template-columns: 1fr 1fr;
}

#minesweeper {
  display: none;
  /* position: absolute; */
  width: calc(min(80%, 80vh));
  height: calc(min(80%, 80vh));
  margin: auto;
  background: url("./minesweeper.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  margin-top: 5vh;
  margin-bottom: 20vh;
}

body.demo #minesweeper.show {
  display: inherit;
}
